import React from 'react';
import Footer from '../../components/client/footer/Footer';
import BannerHome from '../../components/client/pageComponents/home/bannerHome/BannerHome';
import HomeCards from '../../components/client/pageComponents/home/homeCards/HomeCards';
import Navbar from '../../components/client/navbar/Navbar';

export default function Home(){
    return(
        <React.Fragment>
            <Navbar />
            <main className="container mb-5">
                <BannerHome />
                <HomeCards />
            </main>
            <Footer />
        </React.Fragment>
    );
}