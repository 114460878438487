import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import './footer.scoped.scss';

export default function Footer() {
  const { t, i18n } = useTranslation();
  const year = new Date().getFullYear();

  return (
    <footer>
      <div className="container">
        <img
          src="/assets/images/logo.png"
          alt="logo"
          className="image-footer"
        />
        <div className="text-center mt-3">
          <a href="https://www.instagram.com/webplam/" className="icon-link instagram-logo" target="blank">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://www.facebook.com/profile.php?id=100068969378333" className="icon-link facebook-logo" target="blank">
            <i className="fab fa-facebook"></i>
          </a>
          <a href="https://twitter.com/webplam" className="icon-link twitter-logo" target="blank">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://www.linkedin.com/in/webplam-web-3a6302214/" className="icon-link linkedin-logo" target="blank">
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
        <div className="row">
          <div className="col-6 col-md-3 px-5 mt-5">
            <Link to="/"><img
              src="/assets/images/logo_long.png"
              alt="logo"
              className="image-logo-long"
            ></img></Link>
          </div>
          <div className="col-6 col-md-3 mt-5 px-5">
            <h3 className="footer-info-title">{t('HOME_PAGE.OUR_SERVICES')}</h3>
            <a className="footer-link mt-3" href="/services/#webs">{t('SERVICES_PAGE.WEBS')}</a>
            <a className="footer-link mt-3" href="/services/#productions">{t('SERVICES_PAGE.PRODUCTION')}</a>
            <a className="footer-link mt-3" href="/services/#shops">{t('SERVICES_PAGE.SHOPS')}</a>
            <a className="footer-link mt-3" href="/services/#apps">{t('SERVICES_PAGE.APPS')}</a>
          </div>
          <div className="col-6 col-md-3 mt-5 px-5">
            <h3 className="footer-info-title">{t('READ_MORE')}</h3>
            <Link className="footer-link mt-3" to="/">{t('PAGES.HOME')}</Link>
            <Link className="footer-link mt-3" to="/services">{t('PAGES.SERVICES')}</Link>
            <Link className="footer-link mt-3" to="/about">{t('PAGES.ABOUT')}</Link>
            <Link className="footer-link mt-3" to="/contact">{t('PAGES.CONTACT')}</Link>
          </div>
          <div className="col-6 col-md-3 mt-5 px-5 ">
            <h3 className="footer-info-title">{t('PAGES.CONTACT')}</h3>
            <div className="info">
              <i className="fas fa-phone-alt"></i>
            </div>
            <a href="tel:608338587" className="info info-text" target="blank">608338587</a>
            <div className="info mt-2">
              <i className="fas fa-envelope icon-underline"></i>
            </div> 
              <a href="mailto:info@webplam.com" className="info info-text" target="blank">info@webplam.com</a>
          </div>
        </div>
      </div>

      <div className="last-row mt-4">
        <div className="container">
          <div className="row text-md-start pb-3">
            <div className="col-12 col-md-3 mt-3 px-5">
              <Link to="/privacy-police" className="footer-link">{t('PRIVACY_POLICE')}</Link>
            </div>
            <div className="col-12 col-md-3 mt-3 px-5">
              <Link to="/terms" className="footer-link">{t('TERMS')}</Link>
            </div>
            <div className="col-12 col-md-3 mt-3 px-5">
              <Link to="/cookies" className="footer-link">{t('COOKIES_NAME')}</Link>
            </div>
            <div className="col-12 col-md-3 mt-3 px-5">
              <Link to="/copyright" className="footer-link">Copyright © {year} Webplam</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
