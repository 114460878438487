import React, { useEffect } from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Home from './pages/client/Home';
import About from './pages/client/About';
import MainSpinner from './components/reusable/mainSpinner/MainSpinner';
import Services from './pages/client/Services';
import Contact from './pages/client/Contact';
import PageNotFound from './pages/errors/404/404';
import ScrollToTop from './services/ScrollToTop';
import CookieConsent from "react-cookie-consent";
import { useTranslation } from 'react-i18next';
import PrivacyPolice from './pages/client/PrivacyPolice';
import Terms from './pages/client/Terms';
import Cookies from './pages/client/Cookies';
import Copyright from './pages/client/Copyright';

export default function Routes(){
    const { t, i18n } = useTranslation();

    function cookiesComponent(){
        return(
            <CookieConsent
                location="bottom"
                buttonText={t("COOKIES.BUTTON")}
                cookieName="cookies"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px", borderRadius: '3px' }}
                expires={150}
            >
              {t("COOKIES.TEXT")} <a href="/cookies" className="d-block d-md-inline-block mx-md-2" style={{ color: "#FFD32D"  }}>{t("SEE_MORE")}</a>
            </CookieConsent>
        );
    }

    return (
        <BrowserRouter>
        <ScrollToTop />
            <Switch>
                <Route exact path="/copyright" component={Copyright} />
                <Route exact path="/cookies" component={Cookies} />
                <Route exact path="/terms" component={Terms} />
                <Route exact path="/privacy-police" component={PrivacyPolice} />
                <Route exact path="/spinner" component={MainSpinner} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/services" component={Services} />
                <Route exact path="/about" component={About} />
                <Route exact path={['/','/home']} component={Home} />
                <Route path="" component={PageNotFound} />
            </Switch>
            {cookiesComponent()}
        </BrowserRouter>
    );   
}