import React from 'react';
import './404.scoped.scss'
export default function PageNotFound(){

    return(
        
        <React.Fragment>
            <div className="body">
            <div className="mainbox">
                <div className="err">4</div>
                <i className="far fa-question-circle fa-spin"></i>
                <div className="err2">4</div>
                <div className="msg">Error, pàgina no trobada
                    <p><a href="/" className="go-to-home">Anar a l'inici</a> </p>
                </div>
            </div>
        </div>
        </React.Fragment>
    );



}