import React from "react";
import Banner from "../../components/client/banner/Banner";
import Footer from "../../components/client/footer/Footer";
import Navbar from '../../components/client/navbar/Navbar';
import ContactBanner from "../../components/client/pageComponents/contact/ContactBanner/ContactBanner";
import ContactForm from "../../components/client/pageComponents/contact/contactForm/ContactForm";

export default function Contact(){



    return (
        <React.Fragment>
            <Navbar />
            <main style={{minHeight: '80vh'}}>
                <ContactBanner />
                {/*<ContactForm />*/}
            </main>
            <Footer />
        </React.Fragment>
    );
}