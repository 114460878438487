import { useHistory } from "react-router-dom";

export function PushRoute(route: string,event?:any){
    const history = useHistory();
    if(event){
        event.preventDefault();
    }
    history.push(route);
    window.scrollTo(0,0);
}

export function blurAll(){
    var tmp = document.createElement("input");
    document.body.appendChild(tmp);
    tmp.focus();
    document.body.removeChild(tmp);
}

export function validateEmail(email:string){
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email && re.test(String(email).toLowerCase());
}

export function validatePhone(phone:any){
    return phone && phone.match(/\d/g).length===9;
}

export function validateStringNotEmpty(string:string){
    return string!=null && string.trim()!=='';
}