import './jumbotron.scoped.scss';
export default function Jumbotron(props:any){
    return(
        <div className="container">
            {props.children}
        </div>
    );
}

Jumbotron.Row = function JumbotronRow(props:any){
    return (
        <div className="row mt-5" id={props.id}>
            {props.children}
        </div>
    );
}

Jumbotron.Column = function JumbotronColumn(props:any){
    return (
        <div className={`col-12 col-md-6 py-3 px-4 ${props.className}`}>
            {props.children}
        </div>
    );
}

Jumbotron.Title = function JumbotronTitle(props:any){
    return (
        <h2>{props.children}</h2>
    );
}

Jumbotron.Subtitle = function JumbotronSubtitle(props:any){
    return (
        <h5>{props.children}</h5>
    );
}

Jumbotron.Image = function JumbotronImage(props:any){
    return (
        <img src={props.src} alt={props.alt} className="jumbotron-image"/>
    );
}

