import { combineReducers, createStore } from 'redux';
import LoaderReducer from './reducers/LoaderReducer'


export default function MainReducer(){
    const reducers = combineReducers({
        LoaderReducer
    })

    return createStore( reducers )
}
