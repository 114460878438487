import React from "react";
import { useTranslation } from "react-i18next";
import { blurAll } from "../../../services/Utils";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import './navbar.scoped.scss';

export default function Header() {
  const history = useHistory();

  const pages = [
    {
      name: "HOME",
      url: "/",
    },
    {
      name: "SERVICES",
      url: "/services",
    },
    {
      name: "ABOUT",
      url: "/about",
    },
    {
      name: "CONTACT",
      url: "/contact",
    },
  ];

  const { t, i18n } = useTranslation();
  const languages = [
    {
      code: "ca",
      name: "CATALAN",
      image_url: "/assets/images/lang_flags/ca_flag.png",
    },
    {
      code: "es",
      name: "SPANISH",
      image_url: "/assets/images/lang_flags/es_flag.png",
    },
    {
      code: "en",
      name: "ENGLISH",
      image_url: "/assets/images/lang_flags/en_flag.png",
    },
  ];
  const products = [
    {
      url: "https://glodmin.com",
      name: "GLODMIN",
      image_url: "/assets/images/web/products/glodmin.png",
    },
    {
      url: "https://pdfsignify.com",
      name: "PDF SIGNIFY",
      image_url: "/assets/images/web/products/pdfsignify.png",
    },
    {
      url: "https://postmetoo.com",
      name: "POSTMETOO",
      image_url: "assets/images/web/products/postmetoo.png",
    },
    {
      url: "https://appook.com",
      name: "APPOOK",
      image_url: "assets/images/web/products/appook.png",
    },
  ];
  let lang_selected: any = [];
  const select_options: any = [];
  languages.forEach((element) => {
    select_options.push({
      value: element.code,
      label: (
        <div className="div-select">
          <div>
            <img
              className="flag-image"
              src={element.image_url}
              alt={element.code}
            />{" "}
          </div>
          &nbsp;&nbsp;
          <div className="d-none d-xl-block">
            {t(`LANGUAGES.${element.name}`)}
          </div>
        </div>
      ),
    });
  });

  const products_options: any = [];
  products.forEach((element) => {
    products_options.push({
      value: element.name,
      label: (
          <a href={element.url} target={'_blank'} style={{textDecoration: 'none',color: 'black'}} className="div-select">
            <div>
              <img
                  style={{maxHeight: 25,maxWidth:25}}
                  src={element.image_url}
                  alt={element.name}
              />{" "}
            </div>
            &nbsp;&nbsp;
            <div>
              {element.name}
            </div>
          </a>
      ),
    });
  });

  setLanguageOptionSelected();

  function changeLang(e: any) {
    i18n.changeLanguage(e.value);
    setLanguageOptionSelected();
    blurAll();
  }

  function setLanguageOptionSelected() {
    const index = languages.findIndex((element) => {
      return element.code === i18n.language;
    });
    lang_selected = select_options[index];
  }

  function changePage(route: string, event?: any) {
    if (event) {
      event.preventDefault();
    }
    history.push(route);
  }

  function collapseClicked() {
    // setTimeout(() => {
    //   blurAll();
    // }, 250);
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light shadow-sm">
      <div className="container-fluid px-5 ">
        <a
          className="navbar-brand"
          href="/"
          onClick={(e) => changePage("/", e)}
        >
          <img
            src="/assets/images/logo_long.png"
            className="img-fluid image-logo mx-2"
            alt="logo"
          ></img>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => collapseClicked()}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto text-center">
            {pages.map((page, i) => (
                <li className='nav-item my-1 mx-2' key={i}>
                  <a
                      className={`nav-link page-option ${
                          page.url === history.location.pathname ? "activated" : ""
                      }`}
                      aria-current="page"
                      href={page.url}
                      onClick={(e) => changePage(page.url, e)}
                  >
                    {t(`PAGES.${page.name}`)}
                  </a>
                </li>
            ))}
            <li className="nav-item mx-2 my-1 page-option">
              <Select
                  styles={{
                    control: base => ({
                      ...base,
                      border: 0,
                      // This line disable the blue border
                      boxShadow: 'none',
                      background: 'transparent',
                      cursor: 'pointer'
                    }),
                    indicatorSeparator: base => ({
                      ...base,
                      display: 'none'
                    }),
                    dropdownIndicator: base => ({
                      ...base,
                      color: "black",
                      ":hover": {
                        color: 'black'
                      }
                    }),
                    placeholder: (defaultStyles) => {
                      return {
                        ...defaultStyles,
                        color: 'black',
                      }
                    }
                  }}
                  options={products_options}
                  className="select select-products"
                  placeholder={t('PRODUCTS')}
                  value={null}
                  menuShouldScrollIntoView={false}
                  closeMenuOnScroll={true}
                  isSearchable={false}
                  id="language_selector"
              />
            </li>
            <li className="nav-item mx-2 my-1">
              <Select
                  options={select_options}
                  className="select"
                  value={lang_selected}
                  onChange={changeLang}
                  menuShouldScrollIntoView={false}
                  closeMenuOnScroll={true}
                  isSearchable={false}
                  id="language_selector"
              />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
