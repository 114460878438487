import { useTranslation } from 'react-i18next';
import './contactBanner.scoped.scss';

export default function ContactBanner(){
    const {t,i18n} = useTranslation();
    var loadjs = require('loadjs');
    loadjs("https://www.google.com/recaptcha/api.js");


    return(
        <div>
            <div className="div-banner text-center pt-5" style={{ background:'url("/assets/images/web/banners/bannerTech.png")' }}>
                <div className="container-inside">
                    <h1 className="contact-title">{t('CONTACT_PAGE.CONTACT_TITLE')}</h1>
                </div>                
            </div>

            <div className="container">
            <div className="row negative-margin justify-content-center">
                    <div className="col-6 col-md-3 col-xl-2 mt-3">
                        <div className="card text-center card-contact">
                            <a href="tel:608338587" target="blank" className="mt-2">
                                <i className="fas fa-phone-alt basic-contact"></i>
                            </a>
                            <p className="contact-card-info text-dark">608338587</p>
                        </div>
                    </div>
                    <div className="col-6 col-md-3 col-xl-2 mt-3">
                        <div className="card text-center card-contact">
                            <a href="mailto:info@webplam.com" target="blank" className="mt-2">
                                <i className="fas fa-envelope basic-contact"></i>
                            </a>
                            <p className="contact-card-info text-dark">info@webplam.com</p>
                        </div>
                    </div>
                    <div className="col-6 col-md-3 col-xl-2 mt-3">
                        <div className="card text-center card-contact">
                            <a href="https://www.instagram.com/webplam/" target="blank" className="mt-2">
                                <i className="fab fa-instagram instagram-contact"></i>
                            </a>
                            <p className="contact-card-info text-dark">@Webplam</p>
                        </div>
                    </div>

                    <div className="col-6 col-md-3 col-xl-2 mt-3">
                        <div className="card text-center card-contact">
                            <a href="https://www.facebook.com/profile.php?id=100068969378333" target="blank" className="mt-2">
                                <i className="fab fa-facebook facebook-contact"></i>
                            </a>
                            <p className="contact-card-info text-dark">@Webplam</p>
                        </div>
                    </div>
                    <div className="col-6 col-md-3 col-xl-2 mt-3">
                        <div className="card text-center card-contact">
                            <a href="https://twitter.com/webplam" target="blank" className="mt-2">
                                <i className="fab fa-twitter twitter-contact"></i>
                            </a>
                            <p className="contact-card-info text-dark">@Webplam</p>
                        </div>
                    </div>
                    <div className="col-6 col-md-3 col-xl-2 mt-3">
                        <div className="card text-center card-contact">
                            <a href="https://www.linkedin.com/in/webplam-web-3a6302214/" target="blank" className="mt-2">
                                <i className="fab fa-linkedin linkedin-contact"></i>
                            </a>
                            <p className="contact-card-info text-dark">@Webplam</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}