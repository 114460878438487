import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import './homeCards.scoped.scss';

export default function HomeCards() {
  const cards = [
    {
      icon: "/assets/icons/fast.svg",
      iconAlt: "SPEED",
      title: "SPEED",
      link: "/services",
    },
    {
      icon: "/assets/icons/efficiency.svg",
      iconAlt: "EFFICIENCY",
      title: "EFFICIENCY",
      link: "/services",
    },
    {
      icon: "/assets/icons/price.svg",
      iconAlt: "PRICE",
      title: "PRICE",
      link: "/services",
    },
    {
      icon: "/assets/icons/scalability.svg",
      iconAlt: "SCALABILITY",
      title: "SCALABILITY",
      link: "/services",
    },
    {
      icon: "/assets/icons/secure.svg",
      iconAlt: "SECURITY",
      title: "SECURITY",
      link: "/services",
    },
    {
      icon: "/assets/icons/contact.svg",
      iconAlt: "DIRECT_CONTACT",
      title: "DIRECT_CONTACT",
      link: "/services",
    },
  ];

  const history = useHistory();
  const { t, i18n } = useTranslation();

    function changePage(e:any,page:string){
        e.preventDefault();
        history.push(page);
        window.scrollTo(0,0);
    }

  return (
    <div className="row mt-5">
      {cards.map((card,i) => (
        <div className="col-12 col-md-6 col-lg-4 mt-5 d-flex align-items-stretch" key={i}>
          <div className="card card-home text-center mt-5 px-5">
            <img
              src={card.icon}
              alt={t(`HOME_PAGE.${card.iconAlt}`)}
              className="icon-card w-25 d-block m-auto mt-5"
            />
            <h3 className="mt-3">{t(`HOME_PAGE.${card.title}`)}</h3>
            <p className="mt-2">{t(`HOME_PAGE.${card.title}_TEXT`)}</p>
            <a className="mt-3 see-more" href={card.link} onClick={(e) => changePage(e,card.link)}>
              {t('SEE_MORE')}
            </a>
          </div>
        </div>
      ))}
    </div>
  );
}
