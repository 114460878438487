import './paragraph.scoped.scss';
export default function Paragraph(props:any){
    return(
        <div className="container mt-5">
            {props.children}
        </div>
    );
}

Paragraph.Title = function ParagraphTitle(props:any){
    return(
        <h1 className="paragraphTitle">{props.children}</h1>
    );
}

Paragraph.Text = function ParagraphText(props:any){
    return(
        <p className="paragraphText mt-3">{props.children}</p>
    );
}