import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import './apiLoader.scss';
import React, { useState } from "react";
import { useSelector } from 'react-redux'

export default function ApiLoder(){
    const showLoader = useSelector((state:any) => state.LoaderReducer.showLoader)    
    return(
        <React.Fragment>
            {showLoader===true && (
                <div className="loader-background" onClick={(event) => event.preventDefault()}>
                    <Loader
                    type="TailSpin"
                    color="#337DAE"
                    height={120}
                    width={120}
                    />
                </div>
            )}
        </React.Fragment>
    );
}