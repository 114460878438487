import React from 'react';
import Footer from '../../components/client/footer/Footer';
import Navbar from '../../components/client/navbar/Navbar';
import Banner from '../../components/client/banner/Banner';
import Paragraph from '../../components/client/paragraph/Paragraph';
import RoundedImage from '../../components/client/roundedImage/RoundedImage';
import { useTranslation } from 'react-i18next';


export default function About(){
    const {t,i18n} = useTranslation();
    return (
        <React.Fragment>
            <Navbar />
            <main>
                <Banner>
                    <Banner.Image src="/assets/images/web/banners/bannerAbout.jpg"></Banner.Image>
                    <Banner.Title>{t('ABOUT_PAGE.ABOUT_TITLE')}</Banner.Title>
                </Banner>
                <Paragraph>
                    <Paragraph.Title>{t('ABOUT_PAGE.ABOUT_SUBTITLE')}</Paragraph.Title>
                    <Paragraph.Text>{t('ABOUT_PAGE.ABOUT_TEXT')}</Paragraph.Text>
                </Paragraph>
                <Banner className="mt-5">
                    <Banner.Image src="/assets/images/web/banners/bannerTech.png"></Banner.Image>
                    <Banner.Title>{t('ABOUT_PAGE.TECHNOLOGIES_TITLE')}</Banner.Title>
                </Banner>
                <RoundedImage>
                    {technologies().map((tech:any) =>
                        <RoundedImage.Column>
                            <RoundedImage.Image src={tech.image} alt={tech.label}/>
                            <RoundedImage.Label>{tech.label}</RoundedImage.Label>
                        </RoundedImage.Column>
                    )}
                </RoundedImage>
                <Banner className="mt-5">
                    <Banner.Image src="/assets/images/web/banners/bannerClients.jpg"></Banner.Image>
                    <Banner.Title>{t('ABOUT_PAGE.CLIENTS_TITLE')}</Banner.Title>
                </Banner>
                <RoundedImage>
                    {clients().map((client:any) =>
                        <RoundedImage.Column>
                            <RoundedImage.Image src={client.image} alt={client.label}/>
                            <RoundedImage.Label>{client.label}</RoundedImage.Label>
                        </RoundedImage.Column>
                    )}
                </RoundedImage>
            </main>
            <Footer />
        </React.Fragment>
    );
}

function technologies(){
    const imageSrcTech = '/assets/images/web/technologies';

   return  [
        {
            image: `${imageSrcTech}/react.png`,
            label: 'React js'
        },
        {
            image: `${imageSrcTech}/react-native.png`,
            label: 'React Native'
        },
        {
            image: `${imageSrcTech}/django.png`,
            label: 'Django'
        },
        {
            image: `${imageSrcTech}/firebase.png`,
            label: 'Firebase'
        },
        {
            image: `${imageSrcTech}/mysql.jpg`,
            label: 'MySQL'
        },
        {
            image: `${imageSrcTech}/html.png`,
            label: 'HTML'
        },
        {
            image: `${imageSrcTech}/css.png`,
            label: 'CSS'
        },
        {
            image: `${imageSrcTech}/js.png`,
            label: 'JavaScript'
        },
        {
            image: `${imageSrcTech}/ts.jpg`,
            label: 'TypeScript'
        },
        {
            image: `${imageSrcTech}/python.png`,
            label: 'Python'
        },
        {
            image: `${imageSrcTech}/node.png`,
            label: 'Node js'
        },
        {
            image: `${imageSrcTech}/gitlab.png`,
            label: 'Gitlab'
        },
    ]
}

function clients(){
    const imageSrcClients = '/assets/images/web/clients';
    return [
        {
            image: `${imageSrcClients}/ltkr.jpg`,
            label: 'LTKR'
        },
        {
            image: `${imageSrcClients}/ratataplam.png`,
            label: 'Gil Ratataplam'
        },
        {
            image: `${imageSrcClients}/cirvianum.png`,
            label: 'Institut Cirviànum'
        },
        {
            image: `${imageSrcClients}/eshop.png`,
            label: 'Eshop'
        },
        {
            image: `${imageSrcClients}/constanti.png`,
            label: 'Ajuntament de Constantí'
        },
        {
            image: `${imageSrcClients}/piera.jpg`,
            label: 'Ajuntament de Piera'
        },
        {
            image: `${imageSrcClients}/sant-julia.jpg`,
            label: 'Ajuntament de Sant Julià de Vilatorta'
        },
        {
            image: `${imageSrcClients}/ticketplam.png`,
            label: 'Ticketplam'
        },
    ]
}