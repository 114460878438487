import './roundedImage.scoped.scss';
export default function RoundedImage(props:any){
    return(
        <div className="container mt-5">
            <div className="row">
                {props.children}
            </div>
        </div>
    );
}

RoundedImage.Column = function RoundedImageColumn(props:any){
    return(
        <div className="col-6 col-md-4 col-lg-3 mt-4">
            {props.children}
        </div>
    );
}
RoundedImage.Image = function RoundedImage(props:any){
    return(
        <div className="circle-radius m-auto">
            <img src={props.src} alt={props.alt} className="image-rounded"></img>
        </div>
    );
}

RoundedImage.Label = function RoundedImageLabel(props:any){
    return(
        <div className="label-container m-auto">
            <label className="label-round-image">{props.children}</label>
        </div>
    );
}