import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
//default imports react
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';
//routes file
//global scss
import './index.scss';
import './i18next';
//service worker and web vitals
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import ApiLoder from './components/reusable/loader/ApiLoader';
//redux
import { Provider } from 'react-redux'
import MainReducer from "./redux/MainReducer";


ReactDOM.render(
  <React.StrictMode>
     <Provider store={MainReducer()}>
        <Suspense fallback={(<div className="spinner-border"></div>)}>
            <ApiLoder />
            <Routes></Routes>
        </Suspense>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
