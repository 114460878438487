import './banner.scoped.scss';
export default function Banner(props:any){

    return (
        <div className={`image-about-background ${props.className}`}>
            {props.children}
        </div>
    );
}


Banner.Title = function BannerTitle(props:any){
    return(
        <h1 className="lh-1 text-over-image">{props.children}</h1>
    );
}

Banner.Image = function BannerImage(props:any){
    return(
        <img src={props.src} alt="Code" className="banner-about"></img>
    );
}