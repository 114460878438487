import React from "react";
import Footer from "../../components/client/footer/Footer";
import Navbar from '../../components/client/navbar/Navbar';
import Jumbotron from '../../components/client/jumbotron/Jumbotron';
import { useTranslation } from "react-i18next";

export default function Services(){
    const { t, i18n } = useTranslation();

    const jumbotronRows = [
        {
            textPosition: 0,
            id: 'software',
            text: 'SOFTWARE',
            imageSrc: '/assets/images/web/programming.jpg',
        },
        {
            textPosition: 1,
            id: 'webs',
            text: 'WEBS',
            imageSrc: '/assets/images/web/webPages.jpg',
        },
        {
            textPosition: 0,
            id: 'productions',
            text: 'PRODUCTION',
            imageSrc: '/assets/images/web/production.jpeg',
        },
        {
            textPosition: 1,
            id: 'shops',
            text: 'SHOPS',
            imageSrc: '/assets/images/web/shop.jpg',
        },
        {
            textPosition: 0,
            id: 'apps',
            text: 'APPS',
            imageSrc: '/assets/images/web/apps.jpg',
        },
    ]



    function jumbotronContentRow(row:any,index:number){
        if(row.textPosition===0){
            return (
                <Jumbotron.Row key={index} id={row.id}>
                    <Jumbotron.Column>
                        <Jumbotron.Title>{t(`SERVICES_PAGE.${row.text}`)}</Jumbotron.Title>
                        <Jumbotron.Subtitle>{t(`SERVICES_PAGE.${row.text}_TEXT`)}</Jumbotron.Subtitle>
                    </Jumbotron.Column>
                    <Jumbotron.Column>
                        <Jumbotron.Image src={row.imageSrc} alt={t(`SERVICES_PAGE.${row.text}`)} />
                    </Jumbotron.Column>
                </Jumbotron.Row>
            );
        }else{
            return (
                <Jumbotron.Row key={index} id={row.id}>
                     <Jumbotron.Column className="d-md-none" >
                         <Jumbotron.Title>{t(`SERVICES_PAGE.${row.text}`)}</Jumbotron.Title>
                        <Jumbotron.Subtitle>{t(`SERVICES_PAGE.${row.text}_TEXT`)}</Jumbotron.Subtitle>
                    </Jumbotron.Column>
                    <Jumbotron.Column className="d-md-none">
                        <Jumbotron.Image src={row.imageSrc} alt={t(`SERVICES_PAGE.${row.text}`)} />
                    </Jumbotron.Column>
                    <Jumbotron.Column className="d-none d-md-block">
                        <Jumbotron.Image src={row.imageSrc} alt={t(`SERVICES_PAGE.${row.text}`)} />
                    </Jumbotron.Column>
                    <Jumbotron.Column className="d-none d-md-block">
                     <Jumbotron.Title>{t(`SERVICES_PAGE.${row.text}`)}</Jumbotron.Title>
                        <Jumbotron.Subtitle>{t(`SERVICES_PAGE.${row.text}_TEXT`)}</Jumbotron.Subtitle>
                    </Jumbotron.Column>
                </Jumbotron.Row>
                
            );
        }
    }



    return (
        <React.Fragment>
            <Navbar />
            <main className="mt-5">
                <Jumbotron>
                    {jumbotronRows.map((row,i) => 
                        jumbotronContentRow(row,i)
                    )}
                </Jumbotron>
            </main>
            <Footer />
        </React.Fragment>
    );
}