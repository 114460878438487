import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import './bannerHome.scoped.scss';

export default function BannerHome(){
    const { t, i18n } = useTranslation();
    const history = useHistory();

    function changePage(page:string){
        history.push(page);
        window.scroll(0,0);
    }


    return (

        <div className="row mt-2 mt-md-5 banner">
            <div className="col-12 col-lg-6 text-center text-lg-start mt-5 p-0 m-0">
                <h1 className="page-title p-0 m-0">{t('HOME_PAGE.TITLE_BANNER')}</h1>
                <h3 className="page-subtitle mt-5">{t('HOME_PAGE.SUBTITLE_BANNER')}</h3>
                <div className="d-grid gap-2 d-lg-block mt-5 py-3 px-2"> 
                    <Link className="btn btn-primary btn-lg button-services" to='/services'>{t('HOME_PAGE.OUR_SERVICES')}</Link>
                    <Link className="btn btn-danger btn-lg button-contact mx-lg-5" to='/contact'>{t('HOME_PAGE.CONTACT')}</Link>
                </div> 
            </div>
            <div className="col-12 col-lg-6 image-div ">
                <img src="/assets/images/web/banners/bannerHome.png" alt="" className="image-banner" />
            </div>

        </div>
    );
}