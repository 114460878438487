import React from "react";
import Footer from "../../components/client/footer/Footer";
import Navbar from "../../components/client/navbar/Navbar";

export default function PrivacyPolice(){




    return (
        <React.Fragment>
            <Navbar></Navbar>
            {getPrivacyPolice()}
            <Footer />
        </React.Fragment>
    );
}


function getPrivacyPolice(){
    return(
        <div className="container mt-5">
            <h1>Privacy Policy for Webplam</h1>
            <p className="mt-5">At webplam, accessible from Webplam, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by webplam and how we use it.</p>
            <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>
            <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in webplam. This policy is not applicable to any information collected offline or via channels other than this website.</p>
            <h2 className="mt-5">Consent</h2>
            <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
        </div>
    );
}