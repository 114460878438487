import React from "react";
import Footer from "../../components/client/footer/Footer";
import Navbar from "../../components/client/navbar/Navbar";

export default function Terms() {
  return (
    <React.Fragment>
      <Navbar />
      {getTerms()}
      <Footer />
    </React.Fragment>
  );
}

function getTerms() {
  return (
    <div className="container mt-5">
      <h2>Information we collect</h2>

      <p className="mt-3">
        The personal information that you are asked to provide, and the reasons
        why you are asked to provide it, will be made clear to you at the point
        we ask you to provide your personal information.
      </p>
      <p>
        If you contact us directly, we may receive additional information about
        you such as your name, email address, phone number, the contents of the
        message and/or attachments you may send us, and any other information
        you may choose to provide.
      </p>
      <p>
        When you register for an Account, we may ask for your contact
        information, including items such as name, company name, address, email
        address, and telephone number.
      </p>

      <h2 className="mt-5">How we use your information</h2>

      <p className="mt-3">We use the information we collect in various ways, including to:</p>

      <ul>
        <li>Provide, operate, and maintain our website</li>
        <li>Improve, personalize, and expand our website</li>
        <li>Understand and analyze how you use our website</li>
        <li>Develop new products, services, features, and functionality</li>
        <li>
          Communicate with you, either directly or through one of our partners,
          including for customer service, to provide you with updates and other
          information relating to the website, and for marketing and promotional
          purposes
        </li>
        <li>Send you emails</li>
        <li>Find and prevent fraud</li>
      </ul>

      <h2 className="mt-5">Log Files</h2>

      <p>
        webplam follows a standard procedure of using log files. These files log
        visitors when they visit websites. All hosting companies do this and a
        part of hosting services' analytics. The information collected by log
        files include internet protocol (IP) addresses, browser type, Internet
        Service Provider (ISP), date and time stamp, referring/exit pages, and
        possibly the number of clicks. These are not linked to any information
        that is personally identifiable. The purpose of the information is for
        analyzing trends, administering the site, tracking users' movement on
        the website, and gathering demographic information.
      </p>
    </div>
  );
}
